import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import FirebaseState from "../../../../state/FirebaseState"
import CurrentUserState from "../../../../state/CurrentUserState"


const useList = ({ listId }) => {
  const { firebase } = FirebaseState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [tasks, setTasks] = useState({})
  const tasksRef = firebase.database().ref(`todo/tasks/${listId}`)

  useEffect(() => {
    tasksRef.on('value', function(snapshot) {
      const val = snapshot.val()
      if (!val) return
      setTasks(val)
    });
  }, [])

  const deleteList = () => {
    firebase.database().ref(`todo/lists/${listId}`).remove()
    firebase.database().ref(`todo/tasks/${listId}`).remove()
  }

  const checkTask = (task) => {
    firebase.database().ref(`todo/tasks/${listId}/unchecked/${task.id}`).remove()
    firebase.database().ref(`todo/tasks/${listId}/checked`).push({
      text: task.text,
      checked: true
    });
  }

  const updateTask = (task, payload) => {
    return firebase.database().ref(`todo/tasks/${listId}/${task.checked || 'unchecked'}/${task.id}`).update({
      ...payload
    })
  }

  const uncheckTask = (task) => {
    firebase.database().ref(`todo/tasks/${listId}/checked/${task.id}`).remove()
    firebase.database().ref(`todo/tasks/${listId}/unchecked`).push({
      text: task.text,
      checked: false
    });
  }

  const deleteTask = (task) => {
    if (task.checked) {
      firebase.database().ref(`todo/tasks/${listId}/checked/${task.id}`).remove()
    } else {
      firebase.database().ref(`todo/tasks/${listId}/unchecked/${task.id}`).remove()
    }
  }

  const addTask = (text) => {
    // const id = name.toLowerCase().replace(' ', '-')
    firebase.database().ref(`todo/tasks/${listId}/unchecked`).push({
      text,
      checked: false
    });
  }

  return { deleteList, tasks, addTask, checkTask, uncheckTask, deleteTask, updateTask }

}

const ListState = createContainer(useList)

export default ListState
