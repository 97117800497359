import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import FirebaseState from "../../../state/FirebaseState"
import CurrentUserState from "../../../state/CurrentUserState"

const MOCKED = {
  "lists": {
    "1": {
      "name": "Voyages",
      "public": true
    }
  },
  "tasks": {
    "1": {
      "checked": [
        { "value": "See each other once!", "checked": true, "createdAt": new Date() }
      ],
      "unchecked": [
        { "value": "Go to Island", "checked": false, "createdAt": new Date() }
      ]
    }
  }
}

const useTodo = () => {
  const { firebase } = FirebaseState.useContainer()
  const { currentUser } = CurrentUserState.useContainer()
  const [rawLists, setRawLists] = useState({})
  const [rawTasks, setRawTasks] = useState({})
  const [lists, setLists] = useState([])
  const listsRef = firebase.database().ref('todo/lists/')
  const tasksRef = firebase.database().ref('todo/tasks/')

  useEffect(() => {
    listsRef.on('value', function(snapshot) {
      const val = snapshot.val()
      if (!val) {
        return setRawLists([])
      }
      setRawLists({ ...Object.keys(val).map(d => ({
        id: d,
        ...val[d]
      })) });
    });
  }, [])

  useEffect(() => {
    setLists(Object.keys(rawLists).map(key => {
      return {
        ...rawLists[key]
      }
    }))
  }, [rawLists])

  const createList = (name) => {
    const id = name.toLowerCase().replace(' ', '-')
    firebase.database().ref('todo/lists/' + name.toLowerCase().replace(' ', '-')).set({
      id,
      name,
      public: true,
      createdBy: currentUser.uid,
      createdAt: new Date()
    });
    firebase.database().ref('todo/tasks/' + name.toLowerCase().replace(' ', '-')).set({
      id,
      checked: [],
      unchecked: []
    });
  }

  return { lists, createList }

}

const TodoState = createContainer(useTodo)

export default TodoState
