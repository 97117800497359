import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import TodoState from './index.state'
import Button from '../../UI/Button'
import List from './List'
import styled from 'styled-components'
import { Previous, Save } from 'grommet-icons'
import { Link } from 'gatsby'
import { colors } from '../../../theme'

const Wrapper = styled.div`
  position: relative;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
  padding: 0 15px 70px 15px;
`

export const Back = styled(Link)`
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Title = styled.div`
  padding-top: 20px;
  font-family: 'Dancing Script', cursive;
  font-size: 39px;
  line-height: 39px;
  color: white;
  text-align: center;
  margin-bottom: 25px;
  > :nth-child(2) {
    margin-right: 10px;
  }
`
const Lists = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 20px;
  > * {
    min-width: 300px;
    width: 100%;
    flex: 1;
    margin-bottom: 20px;
    margin-right: 20px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 0;
    > * {
      width: 100%;
      flex: 1;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`

const NewList = styled.div`
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: #FAD1D3;

  > :first-child {
  display: flex;
  padding: 12px;
    input {
      flex: 1;
      margin-right: 10px;
      outline: none;
      border-radius: 5px;
      border: none;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
      padding: 6px 12px;
      font-size: 25px;
      font-family: 'Dancing Script', cursive;
    }
    > :last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #D35078;
    }
  }
`

const Todo = (props) => {
  const { createList, lists } = TodoState.useContainer()

  const newListName = useRef()

  const onCreateList = () => {
    createList(newListName.current.value)
    newListName.current.value = ''
  }

  return <Wrapper>
    <Title>Les choses à faire</Title>
    <Back to='/'>
      <Previous color="#fff" size="30px" />
    </Back>
    <Lists>
      { lists.map(l => <List key={l.id} listId={l.id} {...l} />)}
    </Lists>
    <NewList>
      <div>
        <input type='text' ref={newListName} placeholder='Nouvelle liste' onKeyPress={e => e.which === 13 && onCreateList()}/>
        <Button onClick={onCreateList}>
          <Save color='#fff' />
        </Button>
      </div>
      <div />
    </NewList>
  </Wrapper>
}

Todo.propTypes = {

}

Todo.defaultProps = {

}

export default ({ listId }) => <TodoState.Provider initialState={{ listId }}>
  <Todo />
</TodoState.Provider>
