import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ListState from './index.state'
import Button from '../../../UI/Button'
import {Wrapper, Title, Task, AddTask, DeleteTask } from './index.styles'
import { Save, Trash, Checkbox, CheckboxSelected, Up, Down } from 'grommet-icons'
import EditableText from '../../../UI/EditableText'

const List = ({ id, name }) => {
  const { addTask, tasks, checkTask, uncheckTask, deleteTask, deleteList, updateTask } = ListState.useContainer()
  const [expanded, setExpanded] = useState(true)
  const newTaskRef = useRef()

  const checkedTasks = Object.keys(tasks.checked || []).map(d => ({ id: d, ...tasks.checked[d] }))
  const uncheckedTasks = Object.keys(tasks.unchecked || []).map(d => ({ id: d, ...tasks.unchecked[d] }))
  
  const onAddTask = () => {
    addTask(newTaskRef.current.value)
    newTaskRef.current.value = ''
  }

  return <Wrapper expanded={expanded}>
    <Title>
      { expanded && <div onClick={() => setExpanded(false)}><Up color='#fff' /></div> }
      { !expanded && <div onClick={() => setExpanded(true)}><Down color='#fff' /></div> }
      <div>{name}</div><div>({checkedTasks.length}/{checkedTasks.length + uncheckedTasks.length})</div>
      <DeleteTask onClick={deleteList}>
        <Trash size='18px' color='#fff' />
      </DeleteTask>
    </Title>
    <div>
    {
      uncheckedTasks.map(t => <Task key={t.id}>
        <div onClick={() => checkTask(t)}>
          <Checkbox color='#ED737A' />
        </div>
        <div><EditableText onEdited={(val) => updateTask(t, { text: val })}>{t.text}</EditableText></div>
        <DeleteTask onClick={(e) => {
          e.stopPropagation()
          deleteTask({ ...t, checked: false })
        }}>
          <Trash size='18px' color='#FAD1D3' />
        </DeleteTask>
      </Task>)
    }
    {
      checkedTasks.map(t => <Task checked key={t.id}>
        <div onClick={() => uncheckTask(t)}>
          <CheckboxSelected color='#ED737A' />
        </div>
        <div>{t.text}</div>
        <DeleteTask onClick={(e) => {
          e.stopPropagation()
          deleteTask({ ...t, checked: true })
        }}>
          <Trash size='18px' color='#FAD1D3' />
        </DeleteTask>
      </Task>)
    }
    <AddTask>
      <input type='text' ref={newTaskRef} placeholder='Un truc trop bien!' onKeyPress={e => e.which === 13 && onAddTask()} />
      <Button onClick={onAddTask}>
        <Save color='#fff' />
      </Button>
    </AddTask>
    </div>
  </Wrapper>
}

List.propTypes = {

}

List.defaultProps = {

}

export default ({ id, name }) => <ListState.Provider initialState={{ listId: id }}>
  <List id={id} name={name} />
</ListState.Provider>
