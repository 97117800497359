import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  background-color: white;
  min-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  > :nth-child(2) {
    display: ${props => props.expanded ? 'initial' : 'none'};
  }
`

export const Title = styled.div`
  position: relative;
  padding: 15px;
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  background-color: #ED737A55;
  color: #DE5F79;
  font-weight: 500;
  display: flex;
  user-select: none;
  > :first-child {
    cursor: pointer;
    margin-right: 10px;
  }
  > :nth-child(3) {
    margin-left: 10px;
    font-size: 14px;
    font-family: 'Libre Baskerville', serif;
    padding-top: 2px;
  }
  padding-right: 50px;
`

export const Task = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  > :first-child {
    align-self: flex-start;
    margin-right: 12px;
  }
  > :nth-child(2) {
    flex: 1;
  }
  > :nth-child(3) {
    align-self: flex-start;
  }
  ${props => props.checked && css`
    text-decoration: line-through;
    color: #CC4678;
  `}
  padding: 15px 35px 15px 15px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
`

export const AddTask = styled.div`
  display: flex;
  padding: 10px;
  > :first-child {
    flex: 1;
    margin-right: 10px;
    outline: none;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    padding: 6px 12px;
  }
  > :last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D35078;
  }
`

export const DeleteTask = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`
