import React from 'react'
import PrivateLayout from '../layouts/private'
import Todo from '../components/pages/Todo'

const TodoPage = () => {
  return <PrivateLayout><Todo /></PrivateLayout>
}

TodoPage.propTypes = {

}

TodoPage.defaultProps = {

}

export default TodoPage
